import { BROWSER } from 'esm-env'
import {
	PUBLIC_WOLTAIR_WEB_CODE,
	PUBLIC_IS_FEATURE_BRANCH,
	PUBLIC_ENVIRONMENT
} from '$env/static/public'

export { default as WpPixelInit } from './WpPixelInit.svelte'

// @ts-ignore
const wpEnabled =
	PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_CZ' &&
	BROWSER &&
	window.wph &&
	PUBLIC_IS_FEATURE_BRANCH === 'false' &&
	PUBLIC_ENVIRONMENT === 'production'

export const wpTrack = async (event: string, data: object = {}) => {
	// @ts-ignore
	if (wpEnabled) window.wph('track', event, data)
}

export const setWpConsent = async (value: boolean) => {
	// @ts-ignore
	if (wpEnabled) window.wph('consent', value === true ? 'grant' : 'revoke')
}
